<template>
  <v-container class="container-full-size">
    <v-card color="basil" style="margin-top: 0" flat>
      <v-col cols="12" class="custom-calendar">
        <div v-if="isLoading" class="custom-loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div class="text-right mb-2">
          <v-btn
            v-if="showTrainingPlanWeeksProgressButton"
            color="warning"
            :class="{ 'mr-4': editPermission }"
            @click="showTrainingPlanWeeksProgress()"
          >
            {{ str['progress'] }}
          </v-btn>
          <v-btn v-if="editPermission" color="success" class="mr-0" @click="openBaseTrainingPlans()">
            {{ str['add_training_plan'] }}
          </v-btn>
        </div>
        <v-sheet>
          <div class="custom-calendar-title pt-0 row-flex-align-space">
            <v-icon color="default" class="cursor-hover" @click="$refs.calendar.prev()">mdi-chevron-left-circle</v-icon>
            <h3>
              {{ $refs.calendar && $refs.calendar.title ? $refs.calendar.title : title }}
            </h3>
            <v-icon color="default" class="cursor-hover" @click="$refs.calendar.next()">mdi-chevron-right-circle</v-icon>
          </div>
        </v-sheet>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="value"
            :locale="language"
            type="month"
            :events="events"
            @click:event="openEvent"
            @click:date="openDay"
            @change="getTrains"
          >
            <template #event="{ event }">
              <div :id="event.id" :style="{ color: event.textColor }">
                {{ event.name }}
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-card>

    <v-dialog v-if="train" v-model="dialogTrain" persistent max-width="1100px">
      <train :title="trainTitle" :client="client" :date="date" :data="train" :save-callback="trainSaved" :close-callback="closeTrain" />
    </v-dialog>

    <v-dialog v-if="baseTrainingPlans" v-model="dialogBaseTrainingPlans" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['trains'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="dialogBaseTrainingPlans" style="height: 500px; padding: 10px 0">
          <v-col cols="12" class="pt-0" style="padding-bottom: 20px; margin-bottom: 10px; border-bottom: 1px solid #ddd">
            <v-row>
              <v-col :cols="12" class="pt-0 pb-0">
                <v-text-field
                  v-model="baseTrainingPlansSearchValue"
                  append-icon="mdi-magnify"
                  :label="str['search']"
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
          <div v-if="!baseTrainingPlansFiltered.length" style="text-align: center; color: #888; padding: 40px 0">
            {{ str['no_data'] }}
          </div>
          <div
            v-for="baseTrainingPlan in baseTrainingPlansFiltered"
            :key="baseTrainingPlan.id"
            class="box-select-item"
            @click="selectBaseTrainingPlan(baseTrainingPlan)"
          >
            {{ baseTrainingPlan.name }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn color="default" @click="dialogBaseTrainingPlans = false">
            {{ str['cancel'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import moment from 'moment'

export default {
  name: 'ClientPlans',
  components: {
    Train: () => import('@/components/dashboard/trainingPlan/Train'),
  },
  props: {
    client: Object,
  },
  data() {
    const user = Utils.getUser()
    const editPermission = Utils.hasPermission('base_trng_plans_edit')
    return {
      str: window.strings,
      language: window.language,
      editPermission: editPermission,
      user: user,
      showTrainingPlanWeeksProgressButton:
        user &&
        user.configurations &&
        user.configurations.training_plans &&
        (user.configurations.training_plans.show_weeks_progress ||
          user.configurations.training_plans.show_weeks_progress_table ||
          user.configurations.training_plans.show_weeks_progress_chart),
      trainEventsDict: this.getTrainEventsDict(),
      isLoading: true,
      title: '',
      trains: [],
      events: [],
      dialogTrain: false,
      train: null,
      trainTitle: '',
      date: null,
      value: '',
      cacheDates: {
        start: null,
        end: null,
      },
      dialogBaseTrainingPlans: false,
      baseTrainingPlans: null,
      baseTrainingPlansSearchValue: '',
      trngPlanIdBase: -999,
    }
  },
  computed: {
    baseTrainingPlansFiltered: function () {
      if (this.baseTrainingPlansSearchValue) {
        const searchValue = this.baseTrainingPlansSearchValue.toLowerCase()
        return this.baseTrainingPlans.filter(function (item) {
          return item.name.toLowerCase().indexOf(searchValue) > -1
        })
      }
      return this.baseTrainingPlans
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
  },
  mounted: function () {
    if (this.$refs.calendar) {
      this.title = this.$refs.calendar.title
    }
  },
  methods: {
    getTrainEventsDict: function () {
      const dict = {}
      const items = Utils.getTrainEvents()
      if (items) {
        for (let i = 0; i < items.length; i++) {
          dict[items[i].value] = {
            label: window.strings[items[i].label] ? window.strings[items[i].label] : items[i].label,
            color: items[i].color,
          }
        }
      }
      return dict
    },
    getTrains({ start, end }) {
      const self = this
      let startDate = ''
      let endDate = ''
      if (start) {
        startDate = start.date
        endDate = end.date
        startDate = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD')
        endDate = moment(endDate).add(1, 'days').format('YYYY-MM-DD')
        if (this.cacheDates.start && this.cacheDates.end && startDate >= this.cacheDates.start && endDate <= this.cacheDates.end) {
          this.dragAndDropEvents()
          return true
        }
        if (!this.cacheDates.start || (this.cacheDates.start && startDate < this.cacheDates.start)) {
          this.cacheDates.start = startDate
        }
        if (!this.cacheDates.end || (this.cacheDates.end && endDate > this.cacheDates.end)) {
          this.cacheDates.end = endDate
        }
      } else {
        startDate = this.cacheDates.start
        endDate = this.cacheDates.end
      }
      Api.getTrains(
        {
          client_id: this.client.dbId,
          start_date: startDate,
          end_date: endDate,
        },
        function (response) {
          self.isLoading = false
          if (response.success) {
            self.trains = response.data
            self.refresh()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    refresh() {
      this.refreshEvents()
      this.dragAndDropEvents()
    },
    refreshEvents() {
      const self = this
      const events = []
      this.trains.forEach(function (item) {
        if (item.date) {
          events.push({
            id: item.id,
            name: self.trainEventsDict[item.name] ? self.trainEventsDict[item.name].label : item.name,
            start: item.date.replace(' ', 'T') + ':00',
            end: item.date.replace(' ', 'T') + ':00',
            description: '',
            color:
              self.trainEventsDict[item.name] && self.trainEventsDict[item.name].color
                ? self.trainEventsDict[item.name].color.background
                : '#2196F3',
            textColor:
              self.trainEventsDict[item.name] && self.trainEventsDict[item.name].color
                ? self.trainEventsDict[item.name].color.text
                : '#fff',
            clientEvent: self.trainEventsDict[item.name] ? true : false,
          })
        }
      })
      this.events = events
    },
    openEvent: function (data) {
      const self = this
      if (this.editPermission) {
        if (data.event.clientEvent) {
          this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
            .then(() => {
              self.$isLoading(true)
              Api.deleteTrain(
                {
                  client_id: self.client.dbId,
                  id: data.event.id,
                },
                function (response) {
                  self.$isLoading(false)
                  if (response.success) {
                    self.getTrains({})
                  } else {
                    self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                  }
                },
              )
            })
            .catch(() => {})
        } else {
          const train = this.trains.find(function (t) {
            return t.id === data.event.id
          })
          this.openTrain(train.date, JSON.parse(JSON.stringify(train)))
        }
      }
    },
    openDay: function (event) {
      if (this.editPermission) {
        this.openTrain(event.date)
      }
    },
    openTrain: function (date, train) {
      this.date = date
      const dateMom = moment(this.date)
      this.trainTitle = Utils.getWeekDay(dateMom) + dateMom.format(', DD-MM-YYYY')
      this.train = train
        ? train
        : {
            name: '',
            steps: [],
          }
      this.dialogTrain = true
    },
    closeTrain: function () {
      this.train = null
      this.dialogTrain = false
    },
    trainSaved: function () {
      this.dialogTrain = false
      this.train = null
      this.getTrains({})
    },
    dragAndDropEvents() {
      const self = this
      setTimeout(() => {
        const events = document.querySelectorAll('.v-event')
        const calendarEvents = document.querySelector('.v-calendar-events')
        events.forEach((event) => {
          event.addEventListener('mousedown', handleMouseDown)
        })

        function handleMouseDown(e) {
          e.preventDefault()
          const draggedElement = e.currentTarget
          const trainId = e.target.id ? parseInt(e.target.id) : null
          if (!trainId) {
            return false
          }
          const rect = draggedElement.getBoundingClientRect()
          const offsetX = e.clientX - rect.left
          const offsetY = e.clientY - rect.top
          const originalWidth = rect.width
          const originalHeight = rect.height
          const calendarRect = calendarEvents.getBoundingClientRect()
          const delay = 200
          let timeout
          timeout = setTimeout(startDrag, delay)
          document.addEventListener('mouseup', cancelDrag)

          function startDrag() {
            const clonedElement = draggedElement.cloneNode(true)
            clonedElement.style.width = originalWidth + 'px'
            clonedElement.style.height = originalHeight + 'px'
            clonedElement.style.position = 'fixed'
            clonedElement.style.left = `${rect.left}px`
            clonedElement.style.top = `${rect.top}px`
            calendarEvents.appendChild(clonedElement)
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)

            function handleMouseMove(e) {
              e.preventDefault()
              let newLeft = e.clientX - offsetX
              let newTop = e.clientY - offsetY
              newLeft = Math.min(Math.max(newLeft, calendarRect.left), calendarRect.right - originalWidth)
              newTop = Math.min(Math.max(newTop, calendarRect.top), calendarRect.bottom - originalHeight)
              clonedElement.style.left = `${newLeft}px`
              clonedElement.style.top = `${newTop}px`
            }

            function handleMouseUp(e) {
              e.preventDefault()
              document.removeEventListener('mousemove', handleMouseMove)
              document.removeEventListener('mouseup', handleMouseUp)
              calendarEvents.removeChild(clonedElement)
              const targetElement = document.elementFromPoint(e.clientX, e.clientY)
              const vCalendarDayElement = targetElement.closest('.v-calendar-weekly__day')
              if (vCalendarDayElement && self.$refs.calendar._data && self.$refs.calendar._data.lastStart.date) {
                const day = parseInt(vCalendarDayElement.textContent)
                if (!isNaN(day)) {
                  const trainIndex = self.trains.findIndex(function (t) {
                    return t.id === trainId
                  })
                  const train = JSON.parse(JSON.stringify(self.trains[trainIndex]))
                  train.date =
                    moment(self.$refs.calendar._data.lastStart.date).format('YYYY-MM-') + (day > 9 ? day.toString() : '0' + day.toString())
                  train.client_id = self.client.dbId
                  Api.editTrain(train, function (response) {
                    if (response.success) {
                      self.trains[trainIndex] = response.data
                      self.$set(self.trains, trainIndex, self.trains[trainIndex])
                      self.refresh()
                    } else {
                      self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
                    }
                  })
                }
              }
            }
          }

          function cancelDrag() {
            clearTimeout(timeout)
          }
        }
      })
    },
    openBaseTrainingPlans: function () {
      const self = this
      this.baseTrainingPlansSearchValue = ''
      if (this.baseTrainingPlans) {
        this.dialogBaseTrainingPlans = true
        return true
      }
      this.$isLoading(true)
      Api.getTrainingPlans(
        {
          fields: ['id', 'name'],
          fast: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.baseTrainingPlans = response.data
            self.dialogBaseTrainingPlans = true
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    selectBaseTrainingPlan: function (item) {
      const self = this
      try {
        this.$isLoading(true)
        Api.getTrainingPlan(
          {
            id: item.id,
          },
          function (response) {
            if (response.success) {
              const data = {}
              const weeksNames = {}
              response.data[0].days.forEach(function (day) {
                if (day.week && day.week_id) {
                  weeksNames[day.week_id] = day.name
                }
              })
              response.data[0].days.forEach(function (day) {
                if (!day.week && day.week_id) {
                  data[day.week_id] = data[day.week_id]
                    ? data[day.week_id]
                    : {
                        value: self.user.configurations.training_plans.weeks.indexOf(weeksNames[day.week_id]),
                        days: [],
                      }
                  data[day.week_id].days.push(day)
                }
              })
              self.addTrains(data)
              self.dialogBaseTrainingPlans = false
            } else {
              self.$isLoading(false)
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } catch {}
    },
    addTrains: function (data) {
      const self = this
      const year = parseInt(moment(this.$refs.calendar._data.lastStart.date).format('YYYY'))
      const month = parseInt(moment(this.$refs.calendar._data.lastStart.date).format('MM'))
      const idsToDelete = []
      this.trains.forEach(function (t) {
        const dateMom = moment(t.date)
        if (parseInt(dateMom.format('YYYY')) === year && parseInt(dateMom.format('MM')) === month) {
          idsToDelete.push(t.id)
        }
      })
      if (idsToDelete.length) {
        Api.deleteTrain(
          {
            client_id: this.client.dbId,
            ids: idsToDelete,
          },
          function (response) {
            if (response.success) {
              add()
            } else {
              self.$isLoading(false)
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        add()
      }

      function add() {
        const trainsToAdd = []
        for (const week in data) {
          data[week].days.forEach(function (train) {
            trainsToAdd.push({
              name: train.name,
              steps: train.steps,
              remarks: train.remarks,
              trng_plan_id: self.trngPlanIdBase,
              date: self.getTrainDate(year, month, data[week].value, train.category),
            })
          })
        }
        if (trainsToAdd.length) {
          Api.newTrain(
            {
              client_id: self.client.dbId,
              items: trainsToAdd,
            },
            function (response) {
              self.$isLoading(false)
              self.getTrains({})
              if (!response.success) {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        } else {
          self.$isLoading(false)
          self.getTrains({})
        }
      }
    },
    getTrainDate: function (year, month, week, day) {
      day += 1
      const firstDayOfMonth = moment([year, month - 1]).startOf('month')
      const firstDayOfWeek = firstDayOfMonth.day()
      let dayOfMonth = week * 7 + day - firstDayOfWeek + 1
      if (dayOfMonth <= 0 || dayOfMonth > firstDayOfMonth.daysInMonth()) {
        return false
      }
      const trainDate = moment([year, month - 1, dayOfMonth])
      return trainDate.format('YYYY-MM-DD')
    },
    showTrainingPlanWeeksProgress: function () {
      this.$router.navigate.push({ path: '/home/trainingPlanWeeksProgress' })
    },
  },
}
</script>
